<template>
  <div class="verify">
    <Nav navType="fun_text" textCon="设置密保问题">
      <template v-slot:rightText>
        <span
          class="iconfont icongengduo"
          style="color:#000;font-size:24px"
        ></span>
      </template>
    </Nav>
    <div class="title_text">设置您的密保问题</div>
    <!-- 设置密保问题 -->
    <div class="question">
      <div class="question_line" v-for="(item,index) in questionList" :key="index">
        <div class="question_label" @click="selectQuestion(index)">
          {{item.question}}
          <span class="iconfont iconqianjin" style="color:#999;font-size:13px"></span>
        </div>
        <Field v-model="item.answer" placeholder="请输入答案" class="input_line answer_line"/>
      </div>
      <div class="button submit" @click="submit()">确认</div>
    </div>
    <!-- 密保问题列表弹窗 -->
    <Popup v-model="show_question_selection" class="question_selection">
      <div class="selection_title">请选择密保问题</div>
      <div class="selection_items" v-for="(item,index) in questionSelection" :key="index" @click="setQuestion(item,index)">
        {{item}}
      </div>
    </Popup>
    <!-- 成功弹窗 -->
    <Popup v-model="show_success" class="success">
      <div class="image"></div>
      <div class="success_text">设置成功</div>
      <div class="success_info">您的密保问题已设置成功</div>
      <div class="success_info">请妥善保管，定期更新</div>
      <div class="success_button" @click="closePage">我知道了</div>
    </Popup>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import { Field, Popup } from "vant" 
import Setting from "@/api/setting"
export default {
  data() {
    return {
      questionList:[
        {question:"请选择密保问题",answer:""},
        {question:"请选择密保问题",answer:""},
        {question:"请选择密保问题",answer:""},
      ],
      show_question_selection: false,
      questionIndex: 0,
      questionSelection:[
        "您的初中学校是？",
        "您的爸爸的生日是？",
        "您最喜欢的明星是？",
        "您的妈妈的生日是？",
        "您的大学学校是？",
        "您大学的专业是？",
        "您的第一份工作是？",
        "总后台自定义问题，已选的不再显示",
        "总后台自定义问题，已选的不再显示",
      ],
      show_success:false,
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){
      this.getSelections()
    },
    // 获取密保问题列表
    async getSelections() {
      console.log('getselections')
      try {
        const res = await Setting.getQuestionSelections();
        if (res.code == 200) {
          console.log(res.data)
          this.questionSelection = res.data
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
    selectQuestion(i){
      this.questionIndex = i
      this.show_question_selection = true;
    },
    setQuestion(item,i){
      console.log(item)
      this.show_question_selection = false;
      if(this.questionList[this.questionIndex].question != "请选择密保问题"){
        var q = this.questionList[this.questionIndex].question
        this.questionSelection.push(q)
      }
      this.questionList[this.questionIndex].question = this.questionSelection[i]
      this.questionSelection.splice(i,1)
    },
    submit(i){
      this.sindex = i;
      this.show_success = true;
    },
    closePage(){
      this.show_success = false;
    },
  },
  components: {
    Nav,
    Field,
    Popup,
  }
};
</script>
<style>
.van-uploader__wrapper{
  height: 100%
}
.van-password-input{
  margin: 0;
}
</style>
<style lang="scss" scoped>
.verify {
  width: 100%;
  min-height: 100%;
  padding: 50px 12px 1px 12px;
  background-color: fff;
  font-family: PingFang SC;
  background-color: #f4f4f4;
  font-weight: 500;
  position: relative;
  .title_text {
    height: 63px;
    line-height: 63px;
    font-size: 15px;
    color: #333;
  }
  .button{
    background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    border-radius: 5px;
    text-align: center;
    color:#fff;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
  }
  .submit{
    margin-top: 18px;
  }
  .input_line{
    height: 45px;
    border-radius: 5px;
    margin-bottom: 12px;
  }
  .question{
    .question_line{
      background: #fff;
      border-radius: 5px;
      padding: 0 10px;
      .question_label{
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        color:#333;
        border-bottom: 1px solid #f4f4f4;
        span{
          float: right;
          margin-left: 3px;
        }
      }
      .answer_line{
        padding-left: 0;
      }
    }
  }
  .question_selection{
    color:#333;
    width:275px;
    max-height: 400px;
    div{
      height: 42px;
      line-height: 42px;
    }
    .selection_title{
      background-color: #fff;
      font-size: 14px;
      width:100%;
      text-align: center;
      border-bottom: 1px solid #f4f4f4;
    }
    .selection_items{
      padding-left: 10px;
      font-size: 13px;
      border-top: 1px solid #f4f4f4;
    }
  }
  .success{
    width:66%;
    padding:0 13px;
    text-align: center;
    .image{
      margin: 0 auto;
      margin-top: 35px;
      width:84px;
      height: 84px;
      border:1px solid #eee;
    }
    .success_text{
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .success_info{
      font-size: 13px;
      line-height: 17px;
    }
    .success_button{
      margin:14px auto 24px auto;
      width:138px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-size: 13px;
      color:#fff;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
  }
}
</style>
